<template>
  <ion-modal
    :is-open="modelValue"
    :breakpoints="[0.1, 0.5, 1]"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-title v-if="!is_incidence">{{ $t('IMÁGENES') }}</ion-title>
          <ion-title v-else>{{ $t('IMÁGENES INCIDENCIA') }}</ion-title>

        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <template v-if="data?.length">
        <swiper
          :modules="modules"
          :zoom="true"
          :pagination="{ clickable: true }"
          :initialSlide="index"
        >
          <swiper-slide v-for="photo in data" :key="`imagen-${photo.id}`">
            <a :href="photo.src" target='_blank'>
              <img
                :src="photo.src"
                style="object-fit: cover; height: 100%"
              />
            </a>
          </swiper-slide>
        </swiper>
      </template>
      <p style="font-size:small; text-align: center;">👉 {{ $t('Pulsa sobre la imagen para verla en tamaño original') }}</p>

    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonContent,
} from '@ionic/vue';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import '@ionic/vue/css/ionic-swiper.css';

import { Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';


export default {
  name: 'MSetImageModal',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    },

    index: {
      type: Number,
    },

    is_offline: {
      type: Boolean,
    },

    is_incidence: {
      type: Boolean,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonContent,
    Swiper,
    SwiperSlide,
  },

  setup() {
    return {
      modules: [Pagination, Zoom],
    };
  },

  methods: {},
};
</script>