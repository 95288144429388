<template>
  <template v-if="type == 'SEP'">
    <ion-list-header>
      <ion-label class="ion-margin-vertical checklabel">{{ label }}</ion-label>
    </ion-list-header>
  </template>

  <template v-if="type == 'CHECK'">
    <ion-item :data-type="type">
      <ion-checkbox
        :disabled="not_editable"
        :checked="!!+modelValue"
        @ionChange="({ target }) => {
          $emit('update:modelValue', target.checked)
          $emit('changedValue', +target.checked)
        }"
      >
        <ion-label class="fieldlabel ion-text-wrap" color="dark" >{{ label }}

          <template v-if="foto">
            <ion-icon
              :icon="imageOutline"
              :style="`color: ${!src || !src?.length ? '#9e9e9e' : 'var(--ion-color-tertiary)'};min-width:24px; min-height:24px`"
              @click.stop.prevent="!src || !src?.length ? $emit('changedValue', null) : $emit('openImageModal', true)"
            />
        </template>
        </ion-label>

      </ion-checkbox>

    </ion-item>
  </template>

  <template v-if="type == 'NUMBER'">
    <ion-item :data-type="type">
      <div class="fieldItem">
        <div class="fieldItem-header">
          <ion-label class="fieldlabel" color="dark" > {{  }} {{ label }}</ion-label>
        </div>
        <div class="fieldItem-content">
          <ion-input
            class="ion-text-right"
            inputmode="decimal"
            type="number"
            placeholder="0"
            :max="max"
            :value="modelValue"
            :disabled="not_editable"
            @ionChange="({ target }) => {
              $emit('update:modelValue', target.value)
              $emit('changedValue', target.value)
            }"
          />

          <template v-if="foto">
            <ion-icon
              :icon="imageOutline"
              :style="`color: ${!src || !src?.length ? '#9e9e9e' : 'var(--ion-color-tertiary)'};min-width:24px; min-height:24px`"
              @click.stop.prevent="!src || !src?.length ? $emit('changedValue', null) : $emit('openImageModal', true)"
            />
          </template>
        </div>
      </div>
    </ion-item>
  </template>

  <template v-if="type == 'TEXTO'">
    <ion-item :data-type="type">
      <div class="fieldItem">
        <div class="fieldItem-header">
          <ion-label class="fieldlabel" color="dark" >{{ label }}</ion-label>
        </div>
        <div class="fieldItem-content">
          <ion-textarea
            :value="modelValue"
            autoGrow
            :placeholder="$t('Escribe aquí')"
            @ionChange="({ target }) => valueChange(target, false)"
            @ionBlur="({ target }) => valueChange(target, true)"
          >
          </ion-textarea>
          <template v-if="foto">
            <ion-icon
              :icon="imageOutline"
              :style="`color: ${!src || !src?.length ? '#9e9e9e' : 'var(--ion-color-tertiary)'};min-width:24px; min-height:24px`"
              @click.stop.prevent="!src || !src?.length ? $emit('changedValue', null) : $emit('openImageModal', true)"
            />
          </template>
        </div>
      </div>
    </ion-item>
  </template>

  <template v-if="type == 'DATE'">
    <ion-item :data-type="type" @click.prevent :id="`open-date-checklist-${id}`">
      <div class="fieldItem">
        <div class="fieldItem-header">
          <ion-label class="fieldlabel" color="dark" >{{ label }}</ion-label>
        </div>
        <div class="fieldItem-content">
          <ion-input
            :value="modelValue?.length ? $moment(modelValue).format('L') : ''"
            :clear-input="true"
            :placeholder="$moment().format('L')"
            readonly
            class="ion-text-wrap ion-text-right"
            @ionChange="({ target }) => valueChange(target, false)"
            @ionBlur="({ target }) => valueChange(target, true)"
        >
        </ion-input>
        <ion-icon slot="start" style="width:24px; height:24px" color="tertiary" :icon="calendarOutline"></ion-icon>

          <template v-if="foto">
            <ion-icon
              :icon="imageOutline"
              :style="`color: ${!src || !src?.length ? '#9e9e9e' : 'var(--ion-color-tertiary)'};min-width:24px; min-height:24px`"
              @click.stop="!src || !src?.length ? $emit('changedValue', null) : $emit('openImageModal', true)"
            />
          </template>
        </div>
      </div>
    </ion-item>

    <ion-modal ref="calendar" class="date-modal" :trigger="`open-date-checklist-${id}`">
      <ion-content force-overscroll="false">
        <!-- <ion-datetime
        :min="$moment().format('YYYY-MM-DD')"
        presentation="date"
        @ionChange="fechaOT"
      /> -->
        <ion-datetime first-day-of-week="1" presentation="date" @ionChange="fechaOT" firstDayOfWeek=1 />
      </ion-content>
    </ion-modal>
  </template>

  <template v-if="type == 'SELECT'">
    <ion-item :data-type="type" :id="`open-modal-${$.uid}`">
      <div class="fieldItem">
        <div class="fieldItem-header">
          <ion-label class="fieldlabel" color="dark" >{{ label }}</ion-label>
        </div>
        <div class="fieldItem-content">
          <ion-input
            readonly
            :value="modelValue || ''"
            :placeholder="$t('Escoge una opción')"
            :disabled="not_editable"
            class="ion-text-wrap ion-text-right select_type_field"
          >
            <ion-icon slot="icon-only" :icon="caretDownOutline"></ion-icon>
          </ion-input>

          <template v-if="foto">
            <ion-icon
              :icon="imageOutline"
              :style="`color: ${!src || !src?.length ? '#9e9e9e' : 'var(--ion-color-tertiary)'};min-width:24px; min-height:24px`"
              @click.stop.prevent="!src || !src?.length ? $emit('changedValue', null) : $emit('openImageModal', true)"
            />
          </template>
        </div>
      </div>

      <ion-modal :ref="`select-modal-${$.uid}`" :trigger="`open-modal-${$.uid}`" :initial-breakpoint="0.95">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="$refs[`select-modal-${$.uid}`].dismiss()">{{
                $t('Cancelar')
              }}</ion-button>
            </ion-buttons>

            <ion-title>{{ label }}</ion-title>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <ion-list>
            <ion-item
              @click="(valueChange({ value: null }, false)), ($refs[`select-modal-${$.uid}`].dismiss())"
            >
              <ion-label>
                <h3>- {{ $t('Ninguna selección') }} -</h3>
              </ion-label>
            </ion-item>

            <ion-item
              v-for="option in options"
              :key="`option-${option.id}`"
              @click="(valueChange({ value: option.opcion }, false)), ($refs[`select-modal-${$.uid}`].dismiss())"
            >
              <ion-label>
                <h3>{{ option.opcion }}</h3>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-modal>

    </ion-item>
  </template>

  <!-- TODO: Queda esta -->
  <template v-if="type == 'LECTURA'">
      <ion-grid>
        <ion-row style="margin-left:5px">
          <ion-label class="ion-text-wrap" color="dark">
            <h3 style="font-weight: 500">{{ label }}</h3>
          </ion-label>
          <ion-col size="12">
            <ion-item>
              <ion-label position="stacked" color="medium" class="ion-text-wrap"> {{ labelLectura }} </ion-label>
              <ion-input
                :value="modelValue"
                placeholder="..."
                type="text"
                inputmode="decimal"
                class="ion-text-wrap"
                @ionChange="({ target }) => $emit('update:modelValue',
                (!!+gmao.comportamientos.x_texto_correcto_checklist && target.value == 'X' ||
                  !!+gmao.comportamientos.x_texto_correcto_checklist && target.value == 'x') ? 'Correcto' : target.value)"
                @ionBlur="({ target }) => $emit('changedValue',
                (!!+gmao.comportamientos.x_texto_correcto_checklist && target.value == 'X' ||
                  !!+gmao.comportamientos.x_texto_correcto_checklist && target.value == 'x') ? 'Correcto' : target.value)"
              ></ion-input>
            </ion-item>
          </ion-col>

          <ion-col size="12">
            <ion-item>
              <ion-label position="stacked" color="medium" class="ion-text-wrap">Observaciones</ion-label>
              <ion-textarea
                :value="lecturaObservaciones"
                class="gmao-textarea ion-text-wrap"
                :autoGrow="true"
                placeholder="..."
                @ionChange="({ target }) => $emit('update:lecturaObservaciones', target.value)"
                @ionBlur="({ target }) => $emit('changedValue:lecturaObservaciones', target.value)"
              ></ion-textarea>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
  </template>
</template>

<script>
import {
  IonListHeader,
  IonLabel,
  IonItem,
  IonCheckbox,
  IonInput,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
} from '@ionic/vue';

import {
  imageOutline,
  calendarOutline,
  caretDownOutline,
} from 'ionicons/icons';

// import { ref } from 'vue';
import { useGmaoStore } from '@/stores/gmao';

export default {
  name: 'CheckField',
  props: {
    modelValue: {
      required: true,
    },

    id: {
      required: false,
      type: Number,
    },

    type: {
      required: true,
      type: String,
    },

    label: {
      required: true,
      type: String,
    },

    not_editable: {
      type: Boolean,
    },

    max: {
      type: Number,
    },


    lecturaObservaciones: {
      type: String
    },

    labelLectura: {
      type: String
    },

    src: {
      type: String
    },

    foto: {
      type: Number,
    },

    options: {
      type: Array,
    }
  },

  components: {
    IonListHeader,
    IonLabel,
    IonItem,
    IonCheckbox,
    IonInput,
    IonTextarea,
    IonGrid,
    IonRow,
    IonCol,
    IonDatetime,

  },

  setup() {
    const gmao = useGmaoStore();
    return {
      gmao,
      imageOutline,
      calendarOutline,
      caretDownOutline,
    };
  },

  methods: {
    valueChange(target, photo) {
      const value = (!!+this.gmao.comportamientos.x_texto_correcto_checklist && target.value == 'X' || !!+this.gmao.comportamientos.x_texto_correcto_checklist && target.value == 'x') ? 'Correcto' : target.value;
      if (target.value?.length && (photo || ['SELECT', 'DATE'].includes(this.type))) {this.$emit('changedValue', value); this.$emit('update:modelValue', value);}
      else this.$emit('update:modelValue', value);
    },

    test(event, t) {
      event.stopPropagation();

      console.log(event, t);
    },

    fechaOT(ev) {
      this.valueChange(ev.target, false);
      // this.$emit('update:modelValue', this.$moment(fecha).format('YYYY-MM-DD'));
      },
  },
};
</script>

<style lang="scss" scoped>
.checklabel {
  font-weight: 600;
  font-size: large;
}

.fieldlabel {
  font-weight: 500;
  font-size: medium;
}

ion-label {
  text-align: left;
  // font-weight: 500;
}

ion-checkbox {
  &::part(label) {
    width: 100%;
  }
}


.fieldItem {
  display: flex;
  flex: 1;
  flex-direction: column;

  &-header {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: medium !important;

    ion-label {
      text-wrap: wrap;
      max-width: 100%;
    }

  }
  &-content {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: flex-end;

    ion-icon {
      padding-bottom: 10px;
    }

  }
}


ion-item[data-type="CHECK"] {

  ion-icon {
    margin-left: 8px;
  }
  ion-label {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

ion-item[data-type="SELECT"] {
  &::part(native) {}

  ion-input {
    flex-direction: row-reverse;
    text-overflow: ellipsis;
    --padding-end: 8px;
    --placeholder-color: rgba(0,0,0, .4); // NOTE: Estos colores hay que centralizarlos...
    ion-icon {
      padding-bottom: 0;
    }
  }

}

ion-item[data-type="NUMBER"] {
  ion-input {
    --placeholder-color: rgba(0,0,0, .4); // NOTE: Estos colores hay que centralizarlos...
  }
}

ion-item[data-type="DATE"] {
  ion-input {
    --placeholder-color: rgba(0,0,0, .4); // NOTE: Estos colores hay que centralizarlos...
  }
}

ion-item[data-type="TEXTO"] {
  &::part(native) {}

  ion-textarea {
    --padding-top: 8px;
    --placeholder-color: rgba(0,0,0, .4); // NOTE: Estos colores hay que centralizarlos...
  }
  ion-icon {
    padding-top: 8px;

  }

}

.date-modal {
  background-color: rgba(0, 0, 0, 0.4);
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

.date-modal ion-datetime {
  height: 382px;
  width: 100%;
}

.select_type_field {
  flex-direction: column;

  ion-label {
    max-width: 100%;
  }
}
.t {
  display: flex;
}
</style>