<template>
  <ion-list lines="none">
    <template v-if="checkDocuments()">
      <ion-accordion-group
        :multiple="true"
      >
        <ion-accordion value="clientes" v-if="clientDocs?.length">
          <ion-item slot="header" lines="none">
            <ion-label>
              <h2 style="font-size: medium">
                {{ $t('documentos-de-cliente') }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-list slot="content">
            <ion-item
              v-for="cl in clientDocs"
              :key="`wo-${cl.id}`"
              :class="['workorder-card', `priority-${cl.prioridad}`]"
              @click="openDoc(cl)"
            >
              <ion-icon
                class="f20"
                slot="start"
                :src="docIcon(cl.path)"
              ></ion-icon>
              <ion-label>
                <h3>{{ cl.nombre || $t('Sin nombre') }}</h3>
                <p>{{ cl.descripcion || $t('Sin descripción') }}</p>
              </ion-label>
              <ion-buttons slot="end" @click="openDoc(cl)">
                <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
              </ion-buttons>
            </ion-item>
          </ion-list>
        </ion-accordion>
        <ion-accordion
          value="direcciones"
          v-if="dirDocs.filter((v) => v?.documentos?.length)?.length"
        >
          <ion-item slot="header" lines="none">
            <ion-label>
              <h2 style="font-size: medium">
                {{ $t('documentos-direccion') }}
              </h2>
            </ion-label>
          </ion-item>

          <ion-list slot="content">
            <!-- DIRECCION DOCS -->
            <template v-for="docs in dirDocs" :key="`direccion-${docs.id}`">
              <ion-label v-if="docs.documentos?.length && dirDocs?.length > 1">
                <p class="ion-padding-start">
                  {{ docs?.nombre || $t('Sin nombre') }}
                </p>
              </ion-label>
              <ion-item
                v-for="doc in docs?.documentos"
                :key="`doc-${doc.id}`"
                :class="['workorder-card']"
                @click="openDoc(doc)"
              >
                <ion-icon
                  class="f20"
                  slot="start"
                  :src="docIcon(doc.path)"
                ></ion-icon>
                <ion-label>
                  <h3>{{ doc.nombre || $t('Sin nombre') }}</h3>
                  <p>{{ doc.descripcion || $t('Sin descripción') }}</p>
                </ion-label>
                <ion-buttons slot="end" @click="openDoc(doc)">
                  <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                </ion-buttons>
              </ion-item>
            </template>
          </ion-list>
        </ion-accordion>
        <ion-accordion
          value="incidencias"
          v-if="
            dirDocs
              .map((v) => v.incidencia)
              .flat()
              .filter((d) => d?.documentos?.length)?.length
          "
        >
          <ion-item slot="header" lines="none">
            <ion-label>
              <h2 style="font-size: medium">
                {{ $t('documentos-incidencia') }}
              </h2>
            </ion-label>
          </ion-item>

          <ion-list slot="content">
            <!-- INCIDENCIA DOCS -->
            <template v-for="docs in dirDocs">
              <template
                v-for="doc in docs?.incidencia"
                :key="`incidencia-${doc.id}`"
              >
                <ion-item
                  v-for="doc in doc.documentos"
                  :key="`doc-${doc.id}`"
                  :class="['workorder-card']"
                  @click="openDoc(doc)"
                >
                  <ion-icon
                    class="f20"
                    slot="start"
                    :src="docIcon(doc.path)"
                  ></ion-icon>
                  <ion-label>
                    <h3>{{ doc.nombre || $t('Sin nombre') }}</h3>
                    <p>{{ doc.descripcion || $t('Sin descripción') }}</p>
                  </ion-label>
                  <ion-buttons slot="end" @click="openDoc(doc)">
                    <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                  </ion-buttons>
                </ion-item>
              </template>
            </template>
          </ion-list>
        </ion-accordion>
        <ion-accordion
          value="partes"
          v-if="
            dirDocs
              .map((v) => v.partes)
              .flat()
              .filter((d) => d?.documentos?.length)?.length ||
            workorderDocs?.length
          "
        >
          <ion-item slot="header" lines="none">
            <ion-label>
              <h2 style="font-size: medium">
                {{ $t('documentos-de-partes') }}
              </h2>
            </ion-label>
          </ion-item>

          <ion-list slot="content">
            <template v-for="docs in dirDocs">
              <!-- PARTES DOCS -->
              <template v-for="doc_parte in docs.partes">
                <ion-item-sliding
                  v-for="doc in doc_parte.documentos"
                  :key="`doc-${doc?.id}`"
                  :class="['workorder-card']"
                  @click="openDoc(doc)"
                >
                <ion-item>
                  <ion-icon
                    class="f20"
                    slot="start"
                    :src="docIcon(doc?.path)"
                  ></ion-icon>
                  <ion-label>
                  </ion-label>
                  <ion-buttons slot="end" @click="openDoc(doc)">
                    <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                  </ion-buttons>
                  </ion-item>
                <ion-item-options side="end">
                  <ion-item-option @click="deleteDocument(doc)" color="danger">
                    <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                    {{ $t('Eliminar') }}
                  </ion-item-option>
                </ion-item-options>
                </ion-item-sliding>
              </template>
            </template>

            <template v-if="workorderDocs?.length">
              <ion-item-sliding
                v-for="doc in workorderDocs"
                :key="`doc-${doc?.id}`"
                :class="['workorder-card']"
              >
              <ion-item @click="openDoc(doc)">
                <ion-icon
                  class="f20"
                  slot="start"
                  :src="docIcon(doc?.path)"
                ></ion-icon>
                <ion-label>
                  <h3>{{ doc?.nombre || $t('Sin nombre') }}</h3>
                  <p>{{ doc?.descripcion || $t('Sin descripción') }}</p>
                </ion-label>
                <ion-buttons slot="end" @click="openDoc(doc)">
                  <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                </ion-buttons>
              </ion-item>
                  <ion-item-options side="end">
                    <ion-item-option @click="deleteDocument(doc)" color="danger">
                      <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                      {{ $t('Eliminar') }}
                    </ion-item-option>
                  </ion-item-options>
              </ion-item-sliding>
            </template>
          </ion-list>
        </ion-accordion>
        <ion-accordion
          value="activos"
          v-if="
            dirDocs
              .map((b) => b?.sistemas)
              .flat()
              .map((b) => b?.maquinas)
              .flat()
              .some((b) => b?.documentos?.length)
          "
        >
          <ion-item slot="header" lines="none">
            <ion-label>
              <h2 style="font-size: medium">
                {{ $t('documentos-de-activos') }}
              </h2>
            </ion-label>
          </ion-item>

          <ion-list slot="content">
            <template v-for="docs in dirDocs">
              <!-- SISTEMAS DOCS -->
              <template v-for="doc_parte in docs.sistemas">
                <template v-for="doc_maquina in doc_parte.maquinas">
                  <!-- MAQUINAS DOCS -->
                  <ion-item
                    v-for="doc in doc_maquina.documentos"
                    :key="`doc-${doc?.id}`"
                    :class="['workorder-card']"
                    @click="openDoc(doc)"
                  >
                    <ion-icon
                      class="f20"
                      slot="start"
                      :src="docIcon(doc?.path)"
                    ></ion-icon>
                    <ion-label>
                      <h3>{{ doc?.nombre || $t('Sin nombre') }}</h3>
                      <p>{{ doc?.descripcion || $t('Sin descripción') }}</p>
                    </ion-label>
                    <ion-buttons slot="end" @click="openDoc(doc)">
                      <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                    </ion-buttons>
                  </ion-item>
                  <!-- MODELO DOCS -->
                  <template
                    v-for="doc in doc_maquina.modelo.documentos"
                    :key="`doc-${doc?.id}`"
                  >
                    <ion-label v-if="doc.id_modelo_maquina && !doc.id_familia">
                      <p class="ion-padding-start">
                        {{ $t('Modelo') }}
                      </p>
                    </ion-label>
                    <ion-item :class="['workorder-card']" @click="openDoc(doc)">
                      <ion-icon
                        class="f20"
                        slot="start"
                        :src="docIcon(doc?.path)"
                      ></ion-icon>
                      <ion-label>
                        <h3>{{ doc?.nombre || $t('Sin nombre') }}</h3>
                        <p>{{ doc?.descripcion || $t('Sin descripción') }}</p>
                      </ion-label>
                      <ion-buttons slot="end" @click="openDoc(doc)">
                        <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                      </ion-buttons>
                    </ion-item>
                  </template>
                  <!-- FAMILIAS DOCS -->
                  <template
                    v-for="doc_familia in doc_maquina.modelo.familias"
                    :key="`doc-familia-${doc_familia.id}`"
                  >
                    <ion-label
                      v-if="
                        (doc.id_modelo_maquina && doc.id_familia) ||
                        (!doc.id_modelo_maquina && doc.id_familia)
                      "
                    >
                      <p class="ion-padding-start">
                        {{ $t('Familia') }}
                      </p>
                    </ion-label>
                    <ion-item
                      v-for="doc in doc_familia.documentos"
                      :key="`doc-${doc?.id}`"
                      :class="['workorder-card']"
                      @click="openDoc(doc)"
                    >
                      <ion-icon
                        class="f20"
                        slot="start"
                        :src="docIcon(doc?.path)"
                      ></ion-icon>
                      <ion-label>
                        <h3>{{ doc?.nombre || $t('Sin nombre') }}</h3>
                        <p>{{ doc?.descripcion || $t('Sin descripción') }}</p>
                      </ion-label>
                      <ion-buttons slot="end" @click="openDoc(doc)">
                        <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                      </ion-buttons>
                    </ion-item>
                  </template>
                </template>
              </template>
            </template>
          </ion-list>
        </ion-accordion>
        <!-- Docs Maquinas - Parte -->
        <ion-accordion
          value="activos"
          v-if="assetDocs.map((a) => a.documentos).flat()?.length"
        >
          <ion-item slot="header" lines="none">
            <ion-label>
              <h2 style="font-size: medium">
                {{ $t('documentos-de-activos') }}
              </h2>
            </ion-label>
          </ion-item>

          <ion-list slot="content">
            <template
              v-for="doc_maquina in assetDocs"
              :key="`maquina-${doc_maquina.id}`"
            >
              <!-- MAQUINAS DOCS -->
              <ion-label
                v-if="doc_maquina.documentos?.length && assetDocs?.length > 1"
              >
                <p class="ion-padding-start">
                  {{
                    `${doc_maquina?.nombre} ${doc_maquina?.modelo?.modelo} ${doc_maquina?.modelo?.marca}` ||
                    $t('Sin nombre')
                  }}
                </p>
              </ion-label>
              <ion-item
                v-for="doc in doc_maquina.documentos"
                :key="`doc-${doc?.id}`"
                :class="['workorder-card']"
                @click="openDoc(doc)"
              >
                <ion-icon
                  class="f20"
                  slot="start"
                  :src="docIcon(doc?.path)"
                ></ion-icon>
                <ion-label>
                  <h3>{{ doc?.nombre || $t('Sin nombre') }}</h3>
                  <p>{{ doc?.descripcion || $t('Sin descripción') }}</p>
                </ion-label>
                <ion-buttons slot="end" @click="openDoc(doc)">
                  <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                </ion-buttons>
              </ion-item>
              <!-- MODELO DOCS -->
              <template
                v-for="doc in doc_maquina.modelo?.documentos"
                :key="`doc-${doc?.id}`"
              >
                <ion-item :class="['workorder-card']" @click="openDoc(doc)">
                  <ion-icon
                    class="f20"
                    slot="start"
                    :src="docIcon(doc?.path)"
                  ></ion-icon>
                  <ion-label>
                    <h3>{{ doc?.nombre || $t('Sin nombre') }}</h3>
                    <p>{{ doc?.descripcion || $t('Sin descripción') }}</p>
                  </ion-label>
                  <ion-buttons slot="end" @click="openDoc(doc)">
                    <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                  </ion-buttons>
                </ion-item>
              </template>
              <!-- FAMILIAS DOCS -->
              <template v-for="doc_familia in doc_maquina.modelo.familias">
                <ion-item
                  v-for="doc in doc_familia.documentos"
                  :key="`doc-${doc?.id}`"
                  :class="['workorder-card']"
                  @click="openDoc(doc)"
                >
                  <ion-icon
                    class="f20"
                    slot="start"
                    :src="docIcon(doc?.path)"
                  ></ion-icon>
                  <ion-label>
                    <h3>{{ doc?.nombre || $t('Sin nombre') }}</h3>
                    <p>{{ doc?.descripcion || $t('Sin descripción') }}</p>
                  </ion-label>
                  <ion-buttons slot="end" @click="openDoc(doc)">
                    <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
                  </ion-buttons>
                </ion-item>
              </template>
            </template>
          </ion-list>
        </ion-accordion>
        <!-- Docs Pedidos de compra -->
        <ion-accordion
          value="pedidos"
          v-if="pedidosDocs?.length"
        >
          <ion-item slot="header" lines="none">
            <ion-label>
              <h2 style="font-size: medium">
                {{ $t('documentos-de-pedidos') }}
              </h2>
            </ion-label>
          </ion-item>

          <ion-list slot="content">
            <ion-item
              v-for="doc_pedido in pedidosDocs"
              :key="`pedido-${doc_pedido.id}`"
              :class="['workorder-card']"
              @click="openDoc(doc_pedido)"
            >
              <ion-icon
                class="f20"
                slot="start"
                :src="docIcon(doc_pedido?.path)"
              ></ion-icon>
              <ion-label>
                <h3>{{ doc_pedido?.nombre || $t('Sin nombre') }}</h3>
                <p>{{ doc_pedido?.descripcion || $t('Sin descripción') }}</p>
              </ion-label>
              <ion-buttons slot="end" @click="openDoc(doc_pedido)">
                <ion-icon :icon="chevronForward" color="tertiary"></ion-icon>
              </ion-buttons>
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
    </template>

    <template v-else>
      <ion-item>
        <ion-label>
          <h3>{{ $t('no-hay-documentos-para-mostrar') }}</h3>
        </ion-label>
      </ion-item>
    </template>
  </ion-list>

  <PDFReader
    v-if="selectedPdf"
    v-model="pdfRead"
    :title="selectedPdf.nombre"
    :url="selectedPdf.path"
    @signed="uploadDoc"
  />

  <ion-modal
    :is-open="imageModal"
    :breakpoints="[0.1, 0.5, 1]"
    :initialBreakpoint="0.95"
    @didDismiss="imageModal = false"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="imageModal = false">{{
            $t('Cancelar')
          }}</ion-button>
        </ion-buttons>

        <ion-title>{{ photo.name || $t('IMÁGENES') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <img :src="photo.path" style="object-fit: contain; width: 100%" />
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref } from 'vue';

import {
  IonAccordion,
  IonAccordionGroup,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  alertController,
  IonItemSliding,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonToolbar,
  IonTitle,
  IonButton,
  IonItemOption
} from '@ionic/vue';

import { chevronForward, closeOutline } from 'ionicons/icons';
import { useGmaoStore } from '@/stores/gmao';

import PDFReader from '@/components/PDFReader.vue';

export default {
  name: 'DocumentTree',
  props: {
    clientDocs: {
      // required: true,
      default: [],
    },

    dirDocs: {
      required: true,
      default: [],
    },

    assetDocs: {
      required: true,
      default: [],
    },

    workorderDocs: {
      required: false,
      default: [],
    },

    pedidosDocs: {
      required: false,
      default: [],
    },

    closeAccordion: {
      default: false,
      type: Boolean,
    },

    fromWorkorder: {
      default: false,
      type: Boolean,
    },
  },

  components: {
    IonAccordion,
    IonAccordionGroup,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemOption,
    IonLabel,
    IonList,
    IonModal,
    IonToolbar,
    IonTitle,
    IonItemSliding,
    IonButton,
    PDFReader
  },

  setup() {
    const gmao = useGmaoStore();

    return {
      chevronForward,
      closeOutline,
      gmao,

      photo: ref({}),
      imageModal: ref(false),

      pdfRead: ref(false),
      selectedPdf: ref(null),
    };
  },

  methods: {
    checkDocuments() {
      if (this.fromWorkorder) {
        return true;
      }
      if (this.dirDocs?.length) {
        return this.dirDocs?.some(
          (d) =>
            d.documentos?.length ||
            d.partes?.some((p) => p.documentos?.length) ||
            d.incidencia?.some((i) => i.documentos?.length) ||
            d.sistemas?.some((s) =>
              s.maquinas?.some(
                (m) =>
                  m.documentos?.length ||
                  m.modelo.documentos?.length ||
                  m.modelo.familias?.some((f) => f.documentos?.length)
              )
            )
        );
      }
    },

    docIcon(type) {
      const extension = /(?:\.([^.]+))?$/;

      let path;
      switch (extension.exec(type)[1]) {
        case 'pdf':
          path = 'assets/file-pdf-regular.svg';
          break;
        case 'png':
        case 'jpeg':
        case 'jpg':
          path = 'assets/file-image-regular.svg';
          break;
        case 'doc':
        case 'docx':
        case 'txt':
          path = 'assets/file-word-regular.svg';
          break;
      }

      return path;
    },

    // async openDoc(doc) {
    //   const path_extract = /(?:\.([^.]+))?$/;
    //   const extension = path_extract.exec(doc.path)[1];
    //   switch (extension) {
    //     case 'pdf':
    //     case 'doc':
    //     case 'docx':
    //     case 'txt':
        
    //       window.open(`${doc.path}`, '_system');
    //       break;

    //     default:
    //       this.photo.name = doc?.nombre;
    //       this.photo.path = doc.path;
    //       this.imageModal = true;
    //       break;
    //   }
    // },

    async openDoc(doc) {
      const path_extract = /(?:\.([^.]+))?$/;
      const extension = path_extract.exec(doc.path)[1];

      switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'bmp':
          // Caso para abrir imágenes
          this.photo.name = doc?.nombre;
          this.photo.path = doc.path;
          this.imageModal = true;
          break;

        default:
          // Descarga por defecto para otros tipos de archivo
            window.open(`${doc.path}`, '_system');

      }
    },

    deleteDocument(row){
      console.log(row);
      const formData = new FormData();
      formData.append('id', row.id || '');
      this.alertPopup(
        this.$tc(
          'seguro-que-quieres-quitar-el-documento-active-nombre-del-parte-this-workorder-id',
          [row?.nombre, row.id_parte]
        ),
        () => {
      this.$axios
        .post(
          `/v2/users/actions.php?call=setDeleteDocuments&token=${this.gmao.user.token}&v2=1`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        .then(({ data }) => {
          console.log('1');

          if (data.response == 500) {
            this.$openToastObject(this.$t('error-con-los-permisos'), data?.error_message, 'danger');
          } else {
            this.documento = {};
          }

          this.$emit('deleted', data);
        }).catch((error) => {
          // TODO: Offline
          console.log(error);

        })},
        { cancel: 'cancelar', confirm: 'quitar' }
        );
    },

    async alertPopup(header, callback, { cancel, confirm } = { cancel: 'cancel', confirm: 'confirm' }) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header,
        buttons: [
          {
            text: this.$t(cancel),
            role: 'cancel',
            cssClass: 'secondary',
            id: 'cancel-button',
          },
          {
            text: this.$t(confirm),
            id: 'confirm-button',
            handler: callback,
          },
        ],
      });

      await alert.present();
    },

    // uploadDoc(file) {
    //   const formData = new FormData();
    //   formData.append('document', this.selectedPdf.id);
    //   formData.append('file', file, `${this.selectedPdf.nombre}.pdf`);

    //   this.$axios
    //     .post(
    //       `/v2/users/actions.php?call=setSignatureDocument&token=${this.gmao.user.token}&v2=1`,
    //       formData,
    //       {
    //         headers: { 'Content-Type': 'multipart/form-data' },
    //       }
    //     )
    //     .then(() => {
    //       this.selectedPdf = null;
    //       this.pdfRead = false;
    //       this.$emit('requestRefresh');
    //     });
    // }
  },
};
</script>
<style lang="scss" scoped>
ion-item {
  --ion-background-color: white;
}

ion-item[slot="header"] {
  margin-bottom: 0;
}

ion-list {
  --ion-background-color: white;
}

ion-accordion-group > ion-accordion:last-of-type ion-item[slot=header] ion-label {
  --color: black;
}

ion-accordion-group > ion-accordion:last-of-type ion-item[slot=header] {
  // --color: var(--ion-color-tertiary);
  // font-size: 1.3rem !important;
}
</style>