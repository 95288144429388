import moment from 'moment';

// DataProcessingController
import { DataProcessingController } from '@/utils/OfflineModule/DataProcessingController';
const dataProcessingController = new DataProcessingController();

export default async function getController(datos:any, store: any = null): Promise<any> {
    const { functionName } = datos;
    let result: Array<any> | object | any;

    switch (functionName) {
        case 'getPartes_workorders':
            result = await getPartes_workorders(datos, store);
            break;
        case 'getDirecciones_workorders':
            result = await getDirecciones_workorders(datos, store);
            break;
        case 'getParte_general':
            result = await getParte_general(datos);
            break;
        case 'getActivos_general':
            result = await getActivos_general(datos, store);
            break;
        case 'setOperarioAdicional_workorder':
            result = await getOperarioAdicional_workorder(datos, store);
            break;
        case 'getReplies_workorder':
            result = await getReplies_workorder(datos, store);
            break;
        case 'getReplies_item':
            result = await getReplies_workorder(datos, store);
            break;
        case 'getQuestions_workorder':
            result = await getQuestions_workorder(datos, store);
            break;
        case 'setEspecialidad_workorder':
            result = await setEspecialidad_workorder(datos, store);
            break;
        case 'setSubespecialidad_workorder':
            result = await setSubespecialidad_workorder(datos, store);
            break;
        case 'setOperario_workorder':
            result = await setOperario_workorder(datos, store);
            break;
        case 'getTextosPredefinidos_general':
            result = await getTextosPredefinidos_general(datos, store);
            break;
        case 'getModelos_workorder':
            result = await getModelos_workorder(datos, store);
            break;
        case 'getSistemas_workorder':
            result = await getSistemas_workorder(datos, store);
            break;
        case 'getMaquinas_workorder':
            result = await getMaquinas_workorder(datos, store);
            break;
        case 'getArticle_workorder':
            result = await getArticle_workorder(datos, store);
            break;
        case 'getMaterials_workorder':
            result = await getMaterials_workorder(datos, store);
            break;
        case 'getLotesMaterial_workorder':
            result = await getLotesMaterial_workorder(datos, store);
            break;
        case 'getAlmacenesMaterial_workorder':
            result = await getAlmacenesMaterial_workorder(datos, store);
            break;
        case 'getDirecciones_new_workorder':
            result = await getDirecciones_new_workorder(datos, store);
            break;
        case 'getProyectos_new_workorder':
            result = await getProyectos_new_workorder(datos, store);
            break;
        case 'getMaquinasSistemas_workorder':
            result = await getMaquinasSistemas_workorder(datos, store);
            break;
        case 'getParteMaquina_item':
            result = await getParteMaquina_item(datos, store);
            break;
        case 'getVehiculosTex':
            result = await getVehiculosTex(datos, store);
            break;
        case 'getIsWorkingDay':
            result = await getIsWorkingDay(datos, store);
            break;
        case 'getAllTechWorkingDays':
            result = await getAllTechWorkingDays(datos);
            break;
        default:
            throw new Error(`GET SQLITE: La funcion "${functionName}" no existe`);
    }
    
    /** TODO: XXX:
     * Necesitamos mejorar este proceso!
     * Hay que revisar como estamos tratando los datos al devolverlos de la BD
     * Revisar el flujo/logica de la funcion porque se puede simplificar
     * 
     * Seguramente pasar por un filtro de procesar datos para isnertar y restore data para recuperar...
     */
    // Procesar el resultado con restoreData si es un array o un objeto

    if (Array.isArray(result)) {
      return result.map((row:any) => dataProcessingController.restoreData(row));
    } else if (typeof result === 'object' && result !== null) {
      return dataProcessingController.restoreData(result);
    }

    return result;
}
// =======================================================================================
// =========================NOTE: FUNCTIONS START FROM HERE ==============================
// =======================================================================================
async function getParte_general(datos: any): Promise<any> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta para obtener el parte
    const result = await dbEX.query(query);
    const parte = result.values[0] || null;
    
    if (!parte) throw new Error('No se encontró el parte especificado.');

    // Función auxiliar para consultas seguras
    const safeQuery = async (sql: string) => {
      try {
        const result = await dbEX.query(sql);
        return result.values || [];
      } catch (error) {
        const errorMessage = ensureErrorMessage(error);
        console.warn(`Error en la consulta: ${sql}`, errorMessage);
        return [];
      }
    };

    // Dirección
    const direccion = await safeQuery(
      `SELECT * FROM direcciones WHERE id=${parte?.id_direccion || 0} AND (deleted_at IS NULL OR deleted_at='null')`
    );
    parte.direccion = direccion[0] || null;

    // Estado actual
    parte.estado_actual = parte?.estado_actual || null;

    // Gama y subgama
    const gama = await safeQuery(`SELECT * FROM gamas WHERE id=${parte?.id_gama || 0}`);
    parte.gama = gama[0] || null;

    const subGama = await safeQuery(`SELECT * FROM gamas WHERE id_gama=${parte?.id_sub_gama || 0}`);
    parte.sub_gama = subGama[0] || null;

    // Comunicaciones
    parte.comunicaciones = await safeQuery(
      `SELECT * FROM incidencia_comunicaciones WHERE id_parte=${parte?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`
    );

    // Comunicaciones
    parte.imagenes = await safeQuery(
      `SELECT * FROM parte_imagenes WHERE id_parte=${parte?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`
    );

    // Desplazamientos
    parte.desplazamientos = await safeQuery(
      `SELECT * FROM parte_desplazamientos WHERE id_parte=${parte?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`
    );

    // Máquinas
    const maquinas = await safeQuery(`
      SELECT DISTINCT M.*, P.tareas_completadas, P.id_modelo_parte
      FROM maquinas as M
      INNER JOIN parte_maquinas P ON P.id_maquina=M.id WHERE P.id_parte=${parte?.id || 0} AND (P.deleted_at IS NULL OR P.deleted_at='null')`);
    if (maquinas && maquinas.length) {
      const maqs:any = [];
      maquinas.forEach(async (m:any) => {
        // Anomalia
        const anomalia = await safeQuery(`SELECT * FROM modelos_parte_anomalias WHERE id_parte=${parte?.id || 0} ${m?.id ? ` AND id_maquina=${m?.id}` : ''}`);
        // console.log('Anomalia:: ', anomalia);
        
        m.anomalia = anomalia[0] || null

        // Modelo
        const modelo = await safeQuery(`SELECT * FROM maquina_modelos WHERE id=${m?.id_modelo || 0}`);
        // console.log('Modelo:: ', modelo);
        m.modelo = modelo[0] || null;


        // Maquina Checklist
        const checklist = await safeQuery(`SELECT * FROM modelos_parte_campos_respuestas WHERE id_parte=${parte?.id || 0} ${m?.id ? ` AND id_maquina=${m?.id}` : ''} AND (deleted_at IS NULL OR deleted_at='null')`);
        // console.log('Checklist:: ', checklist);
        m.respuestas = checklist.map((r:any) => {
            r.campo = JSON.parse(r.campo);
            return r;
        }) 
        || null;

        maqs.push(m);
      });
      // console.log('After-loop:: ', maqs);
      
      parte.maquinas = maqs;

      if (maqs && maqs.length) {
        const ids = maqs?.map((m:any) => m.id);
        const faq_respuestas = await safeQuery(`SELECT * FROM faq_respuestas_parte_maquinas WHERE id_parte=${parte?.id || 0} AND id_maquina IN (${ids.toString()}) AND (deleted_at IS NULL OR deleted_at='null')`);
        // console.log('Faq respuestas:: ', faq_respuestas);
        parte.maquinas_respuestas = faq_respuestas[0] || null;
      }
    }

    // Materiales y líneas
    parte.lineas = await safeQuery(
      `SELECT * FROM parte_materiales WHERE id_parte=${parte?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`
    );

    // Sistema
    const sistema = await safeQuery(
      `SELECT * FROM sistemas WHERE id_direccion=${parte?.id_direccion || 0} AND (deleted_at IS NULL OR deleted_at='null')`
    );
    parte.sistema = sistema[0] || null;

    // Técnicos asignados
    parte.tecnicos = await safeQuery(
      `SELECT * FROM usuarios WHERE id_parte=${parte?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`
    );

    // Técnicos adicionales
    const tecnicosIds = (parte.tecnicos || []).map((t: any) => t.id_tecnico).toString();
    parte.tecnicos_adicionales = await safeQuery(
      `SELECT DISTINCT * FROM usuarios WHERE id IN (SELECT id_tecnico FROM parte_tecnicos WHERE id_tecnico IN (${tecnicosIds}) AND deleted_at IS NULL) AND id_role IN (5, 9)`
    );

    // Horas con relaciones (tipo_tiempo y técnico)
    const horaPromise = async () => {
      try {
        const horas = await safeQuery(`
          SELECT * FROM parte_horas
          WHERE id_parte=${parte?.id || 0} AND 
              (deleted_at IS NULL OR deleted_at='null')
          ORDER BY id ASC
        `);

        // Mapear las horas para cargar sus relaciones
        parte.horas = await Promise.all(
          horas.map(async (hora: any) => {
            // Obtener tipo de tiempo
            if (hora.id_tipo_tiempo) {
              const tipoTiempo = await safeQuery(`
                SELECT * FROM tipos_tiempo
                WHERE id=${hora.id_tipo_tiempo || 0} AND (deleted_at IS NULL OR deleted_at='null')
              `);
              hora.tipo_tiempo = tipoTiempo[0] || null;
            }

            // Obtener técnico
            if (hora.id_tecnico) {
              const tecnico = await safeQuery(`
                SELECT * FROM usuarios
                WHERE usuarios.id=${hora.id_tecnico} AND usuarios.id_role IN (5, 9)
                AND (usuarios.deleted_at IS NULL OR usuarios.deleted_at='null')
              `);
              hora.tecnico = tecnico[0] || null;
            }

            return hora;
          })
        );
      } catch (error) {
        const errorMessage = ensureErrorMessage(error);
        console.error('Error obteniendo horas del parte:', errorMessage);
        parte.horas = []; // Si hay un error, asignar un array vacío
      }
    };

    await horaPromise();

    // Técnico principal
    const tecnico = await safeQuery(
      `SELECT * FROM usuarios WHERE id=${parte?.id_tecnico || 0} AND (deleted_at IS NULL OR deleted_at='null')`
    );
    parte.tecnico = tecnico[0] || null;

    return parte;
  } catch (error) {
    const errorMessage = ensureErrorMessage(error);
    console.error('Error procesando parte en detalle:', errorMessage);
    // TODO: ErrorController
    throw error;
  }
}

/**
 * Garantiza que siempre se devuelva un mensaje de error.
 * Si el error no es un objeto `Error`, lo convierte en uno.
 */
function ensureErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }

  try {
    // Intentar convertir a string, por ejemplo, si es un objeto genérico o un valor primitivo.
    return JSON.stringify(error);
  } catch {
    // Como último recurso, devolver un string genérico.
    return String(error) || 'Error desconocido';
  }
}


async function getPartes_workorders(datos: any, store: any): Promise<any> {
    const { query, dbEX } = datos;

    try {
        const result = await dbEX.query(query);

        const partes = result.values || [];

        const partesProvide = setUpWorkOrders(partes, dbEX);

        store.workorders = partesProvide;

        return partesProvide;
    } catch (error) {
        console.error('Error en getPartes_workorders:', error);
        // TODO: ErrorController
        throw error;
    }
}

async function getDirecciones_workorders(datos: any, store: any): Promise<void> {
    const { query, dbEX } = datos;

    try {
        // Ejecutar la consulta
        const result = await dbEX.query(query);

        // Actualizar el store con los resultados
        store.addresses = result.values || [];
    } catch (error) {
        console.error('Error en getDirecciones_workorders:', error);
        // TODO: ErrorController
        throw error; // Opcional: propagar el error
    }
}

async function getActivos_general(datos: any, store: any): Promise<void> {
    const { query, dbEX } = datos;

    try {
        // Ejecutar la consulta
        const result = await dbEX.query(query);

        // Procesar los resultados
        const maquinas = result.values || [];
        maquinas.forEach((m: any) => {
        if (m.modelo) {
            m.modelo = JSON.parse(m.modelo);
        }
        });

        // Actualizar el store
        store.assets = maquinas;
    } catch (error) {
        console.error('Error en getActivos_general:', error);
        // TODO: ErrorController
        throw error; // Opcional: Propagar el error si es necesario
    }
}

async function getOperarioAdicional_workorder(datos: any, store: any): Promise<void> {
    const { query, dbEX } = datos;

    try {
        // Ejecutar la consulta
        const result = await dbEX.query(query);

        // Extraer los técnicos de los resultados
        const tecnicos = result.values || [];

        // Actualizar el store
        store.operariosAdicionales = tecnicos;
    } catch (error) {
        console.error('Error en getOperarioAdicional_workorder:', error);
        // TODO: ErrorController
        throw error; // Opcional: Propagar el error si es necesario
    }
}

async function getReplies_workorder(datos: any, store: any): Promise<void> {
    const { query, dbEX } = datos;

    try {
        // Ejecutar la consulta principal
        const result = await dbEX.query(query);
        const respuestas = result.values || [];

        // Procesar cada respuesta para obtener los materiales y almacenes relacionados
        for (const r of respuestas) {
        // Obtener los materiales relacionados con la respuesta
        const materialesResult = await dbEX.query(`
            SELECT DISTINCT A.*, F.cantidad
            FROM articulos as A
            INNER JOIN faq_materiales_respuestas F
            ON A.id=F.id_articulo
            WHERE F.id_respuesta=${r.id || 0} AND (A.deleted_at IS NULL OR A.deleted_at='null');
        `);

        const materiales = materialesResult.values || [];

        // Procesar cada material para obtener los almacenes relacionados
        for (const m of materiales) {
            const almacenResult = await dbEX.query(`
            SELECT *
            FROM almacenes
            WHERE id IN (SELECT id_almacen FROM almacen_articulos WHERE id_articulo=${m.id || 0}) 
                AND (deleted_at IS NULL OR deleted_at='null');
            `);
            m.almacenes = almacenResult.values || [];
        }

        r.materiales = materiales;
        }

        // Actualizar el store con las respuestas procesadas
        store.replies = respuestas;
    } catch (error) {
        console.error('Error en getReplies_workorder:', error);
        // TODO: ErrorController
        throw error; // Propagar el error si es necesario
    }
}

async function getQuestions_workorder(datos: any, store: any): Promise<void> {
    const { query, dbEX } = datos;

    try {
        // Ejecutar la consulta
        const result = await dbEX.query(query);

        // Actualizar el store con las preguntas
        store.questions = result.values || [];
    } catch (error) {
        console.error('Error en getQuestions_workorder:', error);
        // TODO: ErrorController
        throw error; // Opcional: Propagar el error
    }
}

async function setEspecialidad_workorder(datos: any, store: any): Promise<void> {
    const { query, dbEX } = datos;

    try {
        // Ejecutar la consulta
        const result = await dbEX.query(query);

        // Actualizar el store con las gamas
        store.gamas = result.values || [];
    } catch (error) {
        console.error('Error en setEspecialidad_workorder:', error);
        // TODO: ErrorController
        throw error; // Opcional: Propagar el error
    }
}

async function setSubespecialidad_workorder(datos: any, store: any): Promise<void> {
    const { query, dbEX } = datos;

    try {
        // Ejecutar la consulta
        const result = await dbEX.query(query);

        // Actualizar el store con las subgamas
        store.subgamas = result.values || [];
    } catch (error) {
        console.error('Error en setSubespecialidad_workorder:', error);
        // TODO: ErrorController
        throw error; // Opcional: Propagar el error
    }
}

async function setOperario_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Actualizar el store con los técnicos
    store.tecnicos = result.values || [];
  } catch (error) {
    console.error('Error en setOperario_workorder:', error);
    // TODO: ErrorController
    throw error; // Opcional: Propagar el error
  }
}

async function getTextosPredefinidos_general(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Actualizar el store con los textos predefinidos
    store.textosPredefinidos = result.values || [];
  } catch (error) {
    console.error('Error en getTextosPredefinidos_general:', error);
    // TODO: ErrorController
    throw error; // Opcional: Propagar el error
  }
}

async function getModelos_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Actualizar el store con los modelos
    store.modelos = result.values || [];
  } catch (error) {
    console.error('Error en getModelos_workorder:', error);
    // TODO: ErrorController
    throw error; // Opcional: Propagar el error
  }
}

async function getSistemas_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);
    
    // Actualizar el store con los resultados
    store.sistemas = result.values || [];
  } catch (error) {
    console.error('Error en getSistemas_workorder:', error);
    // TODO: ErrorController
    throw error; // Opcional: Propagar el error
  }
}

async function getMaquinas_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Actualizar el store según la lógica condicional
    if (query.includes('parte_maquinas')) {
      store.wo_assets = result.values || [];
    } else {
      store.assets = result.values || [];
    }
  } catch (error) {
    console.error('Error en getMaquinas_workorder:', error);
    // TODO: ErrorController
    throw error; // Opcional: Propagar el error
  }
}

async function getArticle_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Obtener el artículo principal
    const result = await dbEX.query(query[0]);
    const articulo = result.values[0];

    // Si hay datos adicionales en el query, buscar almacenes relacionados
    if (Array.isArray(query) && query[1]) {
      const { id_almacen, id_articulo } = query[1];
      const almacenesResult = await dbEX.query(`
        SELECT * FROM almacen_articulos 
        WHERE id_almacen=${id_almacen} AND id_articulo=${id_articulo}
      `);
      articulo.almacenes = almacenesResult.values || [];
    }

    // Actualizar el store
    store.articulo = articulo;
  } catch (error) {
    console.error('Error en getArticle_workorder:', error);
    // TODO: ErrorController
    throw error;
  }
}

async function getMaterials_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Actualizar el store con los materiales
    store.selMaterials.all.data = result.values || [];
  } catch (error) {
    console.error('Error en getMaterials_workorder:', error);
    // TODO: ErrorController
    throw error;
  }
}


async function getLotesMaterial_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta principal
    const result = await dbEX.query(query);
    const lotes = result.values || [];

    // Para cada lote, buscar detalles adicionales
    for (const l of lotes) {
      const loteResult = await dbEX.query(`
        SELECT * FROM lotes WHERE id=${l?.id_lote || 0}
      `);
      l.lote = loteResult.values[0] || null;
    }

    // Actualizar el store con los lotes
    store.lotes = lotes;
  } catch (error) {
    console.error('Error en getLotesMaterial_workorder:', error);
    // TODO: ErrorController
    throw error;
  }
}

async function getAlmacenesMaterial_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta principal
    const result = await dbEX.query(query[0]);
    const almacenes = result.values || [];

    // Para cada almacén, buscar los artículos relacionados
    for (const al of almacenes) {
      const articuloResult = await dbEX.query(`
        SELECT * FROM articulos WHERE id=${query[1].id_articulo || 0}
      `);
      al.articulos = articuloResult.values || [];
    }

    // Actualizar el store con los almacenes
    store.almacenesArticulo = almacenes;
  } catch (error) {
    console.error('Error en getAlmacenesMaterial_workorder:', error);
    // TODO: ErrorController
    throw error;
  }
}

// FUNCIONES INTERNAS
/**
 * Prepara un listado de partes con información mínima para el listado.
 *
 * @param partes - Array de partes a procesar.
 * @param dbEX - Conexión a la base de datos SQLite.
 * @returns Array de partes con información mínima.
 */
async function setUpWorkOrders(partes: any[], dbEX: any): Promise<any[]> {
  const partesProvide: any[] = [];

  for (const parte of partes || []) {
    try {
      // Dirección
      const direccionResult = await dbEX.query(
        `SELECT * FROM direcciones WHERE id=${parte?.id_direccion || 0} AND (deleted_at IS NULL OR deleted_at='null')`
      );
      parte.direccion = direccionResult.values[0] || null;

      // Estado actual (puede estar incluido en el objeto parte directamente)
      parte.estado_actual = parte?.estado_actual || null;

      // Gama y subgama
      const gamaResult = await dbEX.query(`SELECT * FROM gamas WHERE id=${parte?.id_gama || 0}`);
      parte.gama = gamaResult.values[0] || null;

      const subGamaResult = await dbEX.query(`SELECT * FROM gamas WHERE id_gama=${parte?.id_sub_gama || 0}`);
      parte.sub_gama = subGamaResult.values[0] || null;

      // Máquinas
      const maquinasResult = await dbEX.query(
        `SELECT * FROM parte_maquinas WHERE id_parte=${parte?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`
      );
      parte.maquinas = maquinasResult.values;

      // Técnicos adicionales
      // const tecnicosIds = parte.tecnicos.map((t: any) => t.id_tecnico).toString();
      // const tecnicosAdicionalesResult = await dbEX.query(
      //   `SELECT DISTINCT * FROM usuarios WHERE id IN (SELECT id_tecnico FROM parte_tecnicos WHERE id_tecnico IN (${tecnicosIds}) AND deleted_at IS NULL) AND id_role IN (5, 9)`
      // );
      // parte.tecnicos_adicionales = tecnicosAdicionalesResult.values;

      partesProvide.push(parte);
    } catch (error) {
      console.error('Error procesando parte:', parte?.id, error);
      // TODO: ErrorController
    }
  }

  return partesProvide;
}

async function getDirecciones_new_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Actualizar el store con las direcciones
    store.addresses = result.values || [];
  } catch (error) {
    console.error('Error en getDirecciones_new_workorder:', error);
    // TODO: ErrorController
    throw error; // Propagar el error si es necesario
  }
}


async function getProyectos_new_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Actualizar el store con los proyectos
    store.proyectos = result.values || [];
  } catch (error) {
    console.error('Error en getProyectos_new_workorder:', error);
    // TODO: ErrorController
    throw error; // Propagar el error si es necesario
  }
}


async function getMaquinasSistemas_workorder(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Actualizar el store con los activos
    store.assets = result.values || [];
  } catch (error) {
    console.error('Error en getMaquinasSistemas_workorder:', error);
    // TODO: ErrorController
    throw error; // Propagar el error si es necesario
  }
}


async function getParteMaquina_item(datos: any, store: any): Promise<void> {
  const { query: d, dbEX } = datos;

  try {
    if (!d?.length) {
      console.error('Faltan datos en la consulta.');
      store.item_asset = null;
      return;
    }

    const [query, datosAsset] = d;

    // Ejecutar la consulta principal
    const result = await dbEX.query(query);
    const asset = result.values[0];

    if (asset && datosAsset) {
      const queryRespuestas = `
        SELECT * FROM modelos_parte_campos_respuestas
        WHERE id_parte=${datosAsset.wo} AND id_maquina=${asset.id} AND (deleted_at IS NULL OR deleted_at='null' OR deleted_at='');
      `;

      // Consultar respuestas relacionadas
      const respuestasResult = await dbEX.query(queryRespuestas);
      const respuestas = (respuestasResult.values || []).map((r: any) => {
        r.campo = JSON.parse(r.campo);
        return r;
      });

      asset.respuestas = respuestas;
    }

    store.item_asset = asset || null;
    return asset;
  } catch (error) {
    console.error('Error en getParteMaquina_item:', error);
    // TODO: ErrorController
    throw error;
  }
}


async function getVehiculosTex(datos: any, store: any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    store.vehiculos = result.values || [];
  } catch (error) {
    console.error('Error en getVehiculosTex:', error);
    // TODO: ErrorController
    throw error;
  }
}


async function getIsWorkingDay(datos: any, store: any): Promise<void> {
  const { dbEX } = datos;

  try {
    // Consultar vacaciones y festivos
    const vacaciones = await dbEX.query(`SELECT COUNT(*) as count FROM usuarios_vacaciones;`);
    const festivos = await dbEX.query(`SELECT COUNT(*) as count FROM festivos;`);

    // Verificar si existen registros en alguna de las tablas
    const count1 = (vacaciones.values[0]?.count || 0) + (festivos.values[0]?.count || 0);
    store.getIsWorkingDay = count1 > 0;
  } catch (error) {
    console.error('Error en getIsWorkingDay:', error);
    // TODO: ErrorController
    throw error;
  }
}

async function getAllTechWorkingDays(datos:any): Promise<void> {
  const { query, dbEX } = datos;

  try {
    // Ejecutar la consulta
    const result = await dbEX.query(query);

    // Procesar los resultados
    const jornadas = result.values || [];
    
    const formattedData = jornadas.map((d: any) => ({
      id: d.id,
      start: moment(`${d.fecha} ${d.inicio}`).tz('Europe/Madrid').format(),
      stop: d.fecha_fin ? moment(`${d.fecha_fin} ${d.fin}`).tz('Europe/Madrid').format() : undefined,
      coords_start: {
        latitude: d.geo_inicio?.latitude || null,
        longitude: d.geo_inicio?.longitude || null,
      },
      address_coords: {}, // Puedes añadir lógica adicional si es necesario
      vehicle: d.id_vehiculo || null,
      km: d.km_inicio ? { from: d.km_inicio } : null,
    }));

    return formattedData || [];
  } catch (error) {
    // TODO: ErrorController
    console.error('Error en getVehiculosTex:', error);
    throw error;
  }
}
