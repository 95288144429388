<template>
  <ion-app :class="[{ 'scanner-hide': gmao.scanActive }, theme]">
    <ion-router-outlet />

    <div>
      <!-- Modal UpdateApp -->
      <UpdateApp
        :isVisible="updateAppNeeded"
        @update:isVisible="updateAppNeeded = false"
      />
    </div>

  </ion-app>
</template>

<script>
// FIXME: Revisar el Plugin @capacitor/geolocation y poner los permisos en AndroidManifest.xml (lo pongo en App.vue porque no se me ocurre otro lado)

// CHUNK FILES
import('../node_modules/@capacitor/camera/dist/esm/web');
import('../node_modules/@capacitor/geolocation/dist/esm/web');
import('../node_modules/@capacitor/geolocation/dist/esm/index');
import('../node_modules/@capacitor/geolocation/dist/esm/definitions');
import('../node_modules/@ionic/pwa-elements/dist/esm/pwa-camera-modal.entry');
// ***
import('../node_modules/jeep-sqlite/dist/esm/jeep-sqlite.entry');


import(
  '../node_modules/@ionic/pwa-elements/dist/esm-es5/pwa-camera-modal.entry'
);
import(
  '../node_modules/@ionic/pwa-elements/dist/esm-es5/pwa-camera-modal-instance.entry'
);
import('../node_modules/@ionic/pwa-elements/dist/esm-es5/pwa-camera.entry');
// import smartlookClient from 'smartlook-client';

import {
  IonApp,
  IonRouterOutlet,
  toastController,
  getPlatforms,
  isPlatform,
} from '@ionic/vue';

import AppToolbar from '@/components/AppToolbar.vue';
// CHUNK FILES OFFLINE
import Item from '@/views/Item.vue';
import Workorder from '@/views/WorkOrder.vue';
import WorkedDays from '@/views/WorkedDays.vue';

import { defineComponent, getCurrentInstance, ref } from 'vue';
import { useGmaoStore } from '@/stores/gmao';
import { useWorkOrdersStore } from '@/stores/workorders';
// import { registerPlugin, Capacitor } from '@capacitor/core';

// SQLite
import { useSQLite } from 'vue-sqlite-hook';
import { useOfflineStore } from '@/stores/offline';

import UpdateApp from '@/components/UpdateApp.vue';

// const BackgroundGeolocation = registerPlugin('BackgroundGeolocation');


import { Plugins } from '@capacitor/core';
console.log('PLUGINS', Plugins);
const { BackgroundGeolocation } = Plugins;

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    UpdateApp,
  },

  data() {
    return {
      updateAppNeeded: ref(false), // estado inicial del modal
      isToday: ref(false),
    };
  },

  watch: {
    // Observa los cambios en el usuario
    'gmao.user': {
      handler(newUser, oldUser) {
        if (newUser?.id && !oldUser?.id) {
          // Si el usuario cambia de no autenticado a autenticado
          this.checkVersion(); // Verifica la versión
        }
      },
      immediate: true, // Ejecuta esta lógica al montar el componente
    },
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();
    const app = getCurrentInstance();
    const wo = useWorkOrdersStore();

    const platforms = getPlatforms();
    gmao.isMobile = platforms.includes('mobile') || platforms.includes('mobileweb');

    // Init SQLite
    if( app != null) {
      app.appContext.config.globalProperties.$sqlite = useSQLite();
    }

    return {
      gmao,
      offline,
      AppToolbar,
      app,
      Item,
      Workorder,
      WorkedDays,
      UpdateApp,
      wo,
    };
  },

  computed: {
    theme() {
      return `preset-${this.gmao.comportamientos?.color_gmao || '1'}`;
    },
  },

  async created() {
    if (this.gmao.user?.id) {
      this.checkVersion()
    }

    // AXIOS INSTANCE
    this.openToastGeo();
    this.$axios.defaults.baseURL = this.gmao.workspace.api;

    this.$axios.interceptors.response.use((response) => {
      if (response.data?.error === 401) {
        localStorage.clear();
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.controller?.postMessage({
            type: 'USER_LOGGED_OUT'
          });
        }
        this.gmao.$reset();
        this.offline.$reset();

        this.$router.replace({ name: 'login' });
        this.openToastOptions();
      }

      return response;
    });

    // GEOLOCATION
    // https://github.com/capacitor-community/background-geolocation

    if (
      Capacitor.isPluginAvailable('BackgroundGeolocation') &&
      this.gmao.user?.id
    ) {
      // El plugin está disponible, ejecuta las acciones necesarias
      BackgroundGeolocation.ready({
        reset: true,
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        distanceFilter: 10,
        stopTimeout: 1,
        debug: true,
        logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
        stopOnTerminate: false,
        startOnBoot: true
      }).then(state => {
        console.log("BackgroundGeolocation is ready", state);
        if (!state.enabled) {
          BackgroundGeolocation.start(); // Inicia el seguimiento de geolocalización
        }
      });
    } else {
      // El plugin no está disponible (probablemente porque se está ejecutando en el navegador)
      console.warn("El plugin BackgroundGeolocation no está disponible en este entorno.");
    }

    // if (
    //   Capacitor.isPluginAvailable('BackgroundGeolocation') &&
    //   this.gmao.user?.id
    // ) {
    //   BackgroundGeolocation.addWatcher(
    //     {
    //       backgroundTitle: this.$t('geolocalizacion-activada'),
    //       backgroundMessage: this.$t(
    //         'puede-que-la-aplicacion-use-la-geolocalizacion'
    //       ),
    //       requestPermissions: true,
    //       // distanceFilter: 20,
    //       distanceFilter: 50, // El valor mínimo en metros para recibir una actualización
    //       disableElasticity: false, // Permite ajustes basados en la velocidad del usuario
    //       elasticityMultiplier: 2 // Factor de multiplicación para la elasticidad
    //     },
    //     (location, error) => {
    //       if (error) {
    //         if (error.code === 'NOT_AUTHORIZED') {
    //           if (window.confirm(this.$t('alert-geolocalizacion'))) {
    //             BackgroundGeolocation.openSettings();
    //           }
    //         }
    //       }

    //       if (this.gmao.user?.id) {
    //         this.$axios.post(
    //           `/v2/users/actions.php?call=setTecnicoLocation&token=${this.gmao.user.token}&v2=1`,
    //           { data: {
    //             id_tecnico: this.gmao.user.id,
    //             latitude: location.latitude,
    //             longitude: location.longitude,
    //             altitude: location.altitude,
    //             accuracy: location.accuracy,
    //             speed: location.speed,
    //             time: location.time,
    //             platform: getPlatforms()[0],
    //           }},
    //           {
    //             headers: {
    //               'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //           }
    //         );
    //       }
    //     }
    //   );
    // }

    //sysadmin@gmao.cloud - Suvxas-8dysvy-zunmer
    //TODO: En web no va y en el emulador tampoco hacer ningun record
    // smartlookClient.init('8ddfaa80a7f7a15a3d8a71c24666819e564abddf');
    // smartlookClient.record;
  },

  methods: {
    async openToastOptions() {
      const toast = await toastController.create({
        header: this.$t('la-sesion-ha-caducado'),
        message: this.$t('por-favor-vuelve-a-iniciar-sesion-para-continuar'),
        position: 'top',
        color: 'danger',
        duration: '4000',
        buttons: [
          {
            text: this.$t('Cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },

    async openToastGeo() {
      const toast = await toastController.create({
        header: this.$t('informacion'),
        message: this.$t('puede-que-la-aplicacion-use-la-geolocalizacion'),
        position: 'bottom',
        color: 'secondary',
        duration: '4000',
        buttons: [
          {
            text: this.$t('ok'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
    
    checkVersion() {
      // compara las versiones
      const userVersion = this.gmao.user?.version
        ? parseInt(this.gmao.user.version.replace(/\./g, ''))
        : 0;
      const appVersion = this.gmao.v
        ? parseInt(this.gmao.v.replace(/\./g, ''))
        : 0;

      // verifica si es un dispositivo móvil
      const isMobile = isPlatform('ios') || isPlatform('android');

      if (isMobile && userVersion > appVersion && !this.updateAppNeeded && this.checkToday()) {
        this.updateAppNeeded = true; // abre el modal
      }
    },

    checkToday() {
      const now = this.$moment();
      const today = now.format('YYYY-MM-DD');
      // recoger ultima vez que se comprobó la actualización
      const lastInteraction = this.gmao.lastUpdateShown

      // si las fechas son diferentes se abre el aviso
      if (lastInteraction !== today || !lastInteraction) {
        this.gmao.lastUpdateShown = today;
        return true;
      }
    },
  },
});
</script>

<style lang="scss">
ion-searchbar.defaultSearchBar {
  --background: #fff;
  --border-radius: 1rem;
}
</style>