<template>
  <ion-modal :is-open="modelValue" @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="1" class="pdf-modal">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{
            $t('Cancelar')
          }}</ion-button>
        </ion-buttons>

        <ion-title>{{ title }}</ion-title>

        <ion-buttons slot="end" @click="signature()">
          <ion-button>{{ $t('Guardar') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="fabricOptions.isDrawingMode = !fabricOptions.isDrawingMode">
              <ion-icon slot="start" :icon="pencilOutline"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-item lines="none">
            <ion-button fill="clear" slot="start" :disabled="currentPage == 1" @click="currentPage -= 1">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>

            <ion-label style="display: flex; align-items: center;">{{ currentPage }} / {{ pages || '' }}</ion-label>

            <ion-button fill="clear" slot="end" @click="currentPage += 1">
              <ion-icon :icon="chevronForward"></ion-icon>
            </ion-button>
          </ion-item>

          <ion-buttons slot="end">
            <ion-button @click="fabricOptions.isDrawingMode = false">
              <ion-icon slot="start" :icon="moveOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ion-content style="height: calc(100% - 112px)">
      <!-- <video class="camera" ref="video" autoplay muted playsinline></video>
      <canvas ref="canvas" hidden></canvas> -->

      <div class="pdf-container">
        <VuePDF
          ref="vuepdf"
          class="overlay"
          :pdf="pdf"
          :page="currentPage"
          fit-parent
          @loaded="loadedPdf"
        >
          <div>Loading...</div>
        </VuePDF>

        <canvas id="canvasDraw"></canvas>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>

import {
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonContent,
  IonModal,
  IonItem,
  IonLabel
} from '@ionic/vue';

import {
  chevronForward,
  chevronBack,
  pencilOutline,
  moveOutline
} from 'ionicons/icons';

import { ref } from 'vue';
import { PDFDocument } from 'pdf-lib';
import * as fabric from 'fabric'

import { VuePDF, usePDF } from '@tato30/vue-pdf'

export default {
  name: 'PDFReader',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    title: {
      type: String,
    },

    url: {
      type: String,
    }
  },

  components: {
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonContent,
    IonModal,
    IonItem,
    IonLabel,
    VuePDF
  },

  setup() {
    return {
      chevronForward,
      chevronBack,
      pencilOutline,
      moveOutline,

      stream: ref(null),
      canvasDraw: ref(null),
      fabricOptions: ref({
        isDrawingMode: true
      }),
      pdf: ref(null),
      pages: ref(null),
      currentPage: ref(1),
      
    };
  },

  // computed: {

  // },

  watch: {
    "fabricOptions.isDrawingMode": function(newVal) {
      this.canvasDraw.isDrawingMode = newVal;
      console.log('Drawing mode:', this.canvasDraw.isDrawingMode);
          console.log('PencilBrush' ,fabric.PencilBrush);

    },

    modelValue(newVal) {
      if(newVal) {
        this.loadPdf();
      }
    }
  },

  created() {
    this.loadPdf();
  },

  methods: {
    loadPdf() {
      const { pdf, pages } = usePDF(this.url);
      this.pdf = pdf;
      this.pages = pages;
    },

loadedPdf() {
  try {
    if (!this.canvasDraw) {
      // Inicializa el canvas
      this.canvasDraw = new fabric.Canvas('canvasDraw', {
        isDrawingMode: true,
      });
      console.log('Canvas initialized:', this.canvasDraw);
      //ESTO SE HA AÑADIDO PORQUE EN EJECUCIÓN EL CANVAS CONTAINER ACABA POR DETRÁS  DEL UPPER-CANVAS Y PONERLE EL Z-INDEX EN EL CSS NO PARECE FUNCIONAR
      const canvasContainer = this.canvasDraw.wrapperEl; // Obtén el contenedor generado por Fabric.js
      if (canvasContainer) {
        canvasContainer.style.position = 'relative'; // Garantiza el posicionamiento
        canvasContainer.style.zIndex = '99'; // Establece el z-index
      }

      // Configura el evento de depuración
      this.canvasDraw.on('mouse:down', (options) => {
        console.log('Mouse down on canvas:', options.pointer);
      });

      // this.canvasDraw.on('path:created', (event) => {
      //   this.canvasDraw.add(event.path); // Asegura que el trazado se mantenga
      //   this.canvasDraw.renderAll(); // Renderiza el canvas después de agregar el objeto
      // });


      // Configura el pincel
      if (fabric.PencilBrush) {
        this.canvasDraw.freeDrawingBrush = new fabric.PencilBrush(this.canvasDraw);
        this.canvasDraw.freeDrawingBrush.width = 5;
        this.canvasDraw.freeDrawingBrush.color = 'black';
      }

      // Ajusta dimensiones
      const overlay = document.getElementsByClassName('overlay')[0];
      if (overlay) {
        this.canvasDraw.setDimensions({
          width: overlay.clientWidth,
          height: overlay.clientHeight,
        });
        console.log('Canvas dimensions set:', overlay.clientWidth, overlay.clientHeight);
      } else {
        console.error('Overlay element not found');
      }

      // Estilo del upper-canvas
      const upperCanvas = this.canvasDraw.upperCanvasEl;
      if (upperCanvas) {
        upperCanvas.style.zIndex = 100; // Asegura que esté encima
      }
    }
  } catch (e) {
    console.error('Error in loadedPdf:', e);
  }
},


    async signature(open_on_browser = false) {
      // GET SIGNATURE
      const image = this.canvasDraw.toDataURL('image/png');

      // OPEN PDF
      const existingPdfBytes = await fetch(this.url).then(res => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // LOAD SELECTED PAGE
      const pages = pdfDoc.getPages();
      const page = pages[this.currentPage - 1];

      // SET SIGNATURE ON PAGE
      const pngImageBytes = await fetch(image).then((res) => res.arrayBuffer());
      const pngImage = await pdfDoc.embedPng(pngImageBytes);
      const pngDims = pngImage.scale(page.getWidth() / pngImage.width);
      
      page.drawImage(pngImage, {
        x: 0,
        y: 0,
        width: pngDims.width,
        height: pngDims.height,
      });

      // SAVE DOC
      const pdfBytes = await pdfDoc.save({ return: 'blob' });
      const file = new Blob([pdfBytes], { type: 'application/pdf' });

      // DOCUMENT READY
      this.$emit('signed', file);

      // DOWNLOAD ON BROWSER
      if(open_on_browser) {
        const fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-container {
  position: relative;
}

.overlay {
  position: absolute !important;
  z-index: 1;
}

.upper-canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index:100;
  pointer-events: auto; /* Habilita eventos del mouse */
}

div.canvas-container {
  position: relative; /* Necesario para aplicar z-index */
  z-index: 90 !important;
  width:400px;
}

.pdf-modal::part(content) {    
    --height: 90%;
    top: 5%;
    --width: 100%;
    display: block;
 }
</style>